@mixin x-shop-dialog-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-shop-dialog {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
    height: 100%;

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    .close {
      display: flex;
      justify-content: flex-end;
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;

      button.mat-mdc-button-base {
        height: 50px;
        width: 50px;
      }
    }

    .content {
      flex: 1;
      overflow: auto;
    }

    header,
    footer,
    .icon,
    .content {
      &:empty {
        display: none;
      }
    }

    footer {
      border-top: 1px solid map-get($custom-colors, borders);
      position: sticky;
      bottom: 0;
      z-index: 1;
      background: white;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .actions {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        > * {
          flex: 1;
          flex-basis: auto;
        }

        &:empty {
          display: none;
        }
      }
    }
  }
}
