@use '@angular/material' as mat;
@mixin x-checkout-requirement-feedback-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-checkout-requirement-feedback {
    margin-bottom: 20px;
    display: block;
    &:empty {
      display: none;
    }

    .requirements {
      background-color: get-custom-color($theme-config, calendar-day-partially-available-bg);
      padding: 20px;
      @include media-breakpoint-down(lg) {
        padding: 10px;
      }

      .requirement {
        display: flex;
        align-items: center;
        justify-content: space-between;

        main {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .msg {
            line-height: normal;
          }
        }

        .actions {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: unset;
          }
        }
      }
    }
  }
}
