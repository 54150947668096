@use '@angular/material' as mat;

@mixin x-checkout-delivery-slot-rows-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-checkout-delivery-slot-rows {
    color: mat.get-color-from-palette(map-get($theme, primary), 500);

    .day {
      @include get-font($theme-config, body-1);
      font-weight: 400;
    }

    .time {
      @include get-font($theme-config, display-4);
    }

    .time-avail {
      .time {
        @include get-font($theme-config, body-1);
        font-weight: 400;
      }

      .availability {
        @include get-font($theme-config, display-4);
      }
    }

    .x-banner-button {
      &.active {
        &.partially-available {
          background-color: get-custom-color($theme-config, calendar-day-partially-available-bg);
          border-color: get-custom-color($theme-config, calendar-day-partially-available-bg);
        }
      }

      &.partially-available:not(.active) {
        .time-avail {
          .availability {
            color: get-custom-color($theme-config, heaviest-warning);
          }
        }
      }
    }
  }
}
